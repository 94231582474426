// find slug by Id
export const findIdBySlug = (slug: any, data: any) => {
    for (const category of data) {
        if (category?.slug === slug) {
            return category?._id;
        }

        for (const subcategory of category?.populatedChildren) {
            if (subcategory?.slug === slug) {
                return subcategory?._id;
            }

            // for (const subSubcategory of subcategory.populatedChildren) {
            //   if (subSubcategory.slug === slug) {
            //     return subSubcategory._id;
            //   }
            // }
        }
    }

    // Return null if not found
    return null;
};

// find id by slug
export const findIdsBySlugs = (data: any, slugs: any[]) => {
    const resultIds: any[] = [];

    slugs?.length > 0 &&
        slugs?.forEach((slug) => {
            const stack = [...data];
            let foundId = null;

            while (stack?.length > 0) {
                const current = stack.pop();

                if (current?.slug === slug) {
                    foundId = current?._id;
                    break;
                }

                if (
                    current?.populatedChildren &&
                    current?.populatedChildren?.length > 0
                ) {
                    stack.push(...current?.populatedChildren);
                }
            }

            if (foundId !== null) {
                resultIds.push(foundId);
            }
        });

    return resultIds;
};

// find id by brand slug
export function getBrandIdFromSlug(list: any[], slugs: string[]): string[] {
    const idArray: string[] = [];
    slugs?.length > 0 &&
        slugs?.forEach((slug) => {
            const item = list?.find((el) => el?.slug === slug);
            if (item) {
                idArray.push(item?._id);
            } else {
                idArray.push(null as any);
            }
        });
    return idArray;
}

// Function to update the third parameter in the URL
export const updateThirdParam = (
    currentUrl: string | URL,
    newThirdParam: string,
    paramName: string
) => {
    const urlObject = new URL(currentUrl);
    // Update the third parameter
    if (
        newThirdParam !== null &&
        newThirdParam !== '' &&
        newThirdParam !== 'undefind' &&
        newThirdParam !== undefined
    ) {
        urlObject.searchParams.set(paramName, newThirdParam);
        // Get the updated URL
        const updatedUrl = urlObject.toString();
        return updatedUrl;
    }
};

export const updateMultipleParams = (
    currentUrl: string | URL,
    paramsToUpdate: Record<string, string>
): string | undefined => {
    const urlObject = new URL(currentUrl);

    // Update each parameter
    Object.entries(paramsToUpdate).forEach(([paramName, paramValue]) => {
        if (
            paramValue !== null &&
            paramValue !== '' &&
            paramValue !== 'undefined' &&
            paramValue !== undefined
        ) {
            urlObject.searchParams.set(paramName, paramValue);
        }
    });

    // Get the updated URL
    return urlObject.toString();
};

// Function to update query parameters in the URL
export const updateQueryParams = (
    currentUrl: string | URL,
    params: Record<string, string | string[] | null | undefined>
) => {
    const urlObject = new URL(currentUrl);

    for (const [paramName, paramValue] of Object.entries(params)) {
        if (
            paramValue !== null &&
            paramValue !== '' &&
            paramValue !== undefined
        ) {
            // If paramValue is an array, join it into a comma-separated string
            const paramValueString = Array.isArray(paramValue)
                ? paramValue.join(',')
                : paramValue.toString();

            // Update the specified query parameter
            urlObject.searchParams.set(paramName, paramValueString);
        }
    }

    // Get the updated URL
    const updatedUrl = urlObject.toString();
    return updatedUrl;
};

export const removeQueryStringParams = (currentUrl: any, paramsObject: any) => {
    const urlObject = new URL(currentUrl);
    const existingParams = Object.keys(paramsObject);

    // Remove query parameters that are not present in the paramsObject
    urlObject.searchParams.forEach((value, key) => {
        if (!existingParams.includes(key)) {
            urlObject.searchParams.delete(key);
        }
    });

    // Update query parameters based on paramsObject
    for (const [paramName, paramValue] of Object.entries(paramsObject)) {
        if (paramValue && Array.isArray(paramValue) && paramValue.length > 0) {
            urlObject.searchParams.set(paramName, paramValue.join(','));
        } else {
            urlObject.searchParams.delete(paramName);
        }
    }

    // Get the updated URL
    return urlObject.toString();
};

export function getIdsForDynamicData(
    data: any[],
    dynamicData: Record<string, string[]>
): string[] {
    const result: string[] = [];

    for (const key in dynamicData) {
        const match =
            data?.length > 0 && data?.find((item) => item._id === key);

        if (match) {
            result.push(
                ...dynamicData[key].flatMap((slug) => {
                    const listItem = match.list.find(
                        (item: any) => item.slug === slug
                    );
                    return listItem ? [listItem._id] : [];
                })
            );
        }
    }

    return result;
}

export function removeNullOrUndefined(
    obj: { [s: string]: unknown } | ArrayLike<unknown>
) {
    return Object.fromEntries(
        Object.entries(obj).filter(
            ([_, value]) =>
                value !== undefined && value !== null && value !== ''
        )
    );
}

export function isPrescriptionRequired(attributes: any) {
    for (const attribute of attributes) {
        // Check if the attribute is 'prescriptionRequired' and its value is 'TRUE'
        if (
            attribute.attribute === 'prescriptionRequired' &&
            attribute.value.toUpperCase() === 'TRUE'
        ) {
            return true; // Prescription is required
        }
    }
    return false; // Prescription is not required
}

export function validatePhoneNumber(inputtxt: string) {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneno.test(inputtxt);
}

export function validateEmail(email: string) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validatePassword(password: string) {
    var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return re.test(String(password));
}

// Function to get unique objects based on the '_id' property
export function getUniqueObjects(arr: any) {
    const seen = new Set();
    return arr.filter((obj: any) => {
        const id = obj._id;
        if (seen.has(id)) {
            return false;
        }
        seen.add(id);
        return true;
    });
}

export function eliminateEmptyArrays(obj: any) {
    for (const key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
            delete obj[key];
        }
    }
    return obj;
}

export function replaceKeysWithDataNames(data: any, enumObject: any) {
    const replacedData: any = {};
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const attributeKey = Object.keys(enumObject).find(
                (enumKey) => enumObject[enumKey] === key
            );
            if (attributeKey) {
                replacedData[attributeKey] = data[key];
            }
        }
    }
    return replacedData;
}

export function addSpaceBeforeCapitalLetters(str: any) {
    return str.replace(/([A-Z])/g, ' $1');
}

export function findIdsBySlugsInDynamicFilter(
    slugData: string[],
    dynamicFilters: any
) {
    const foundIds: any = [];

    // Iterate over each filter type
    for (const filterType in dynamicFilters) {
        // Iterate over each filter object within the filter type
        dynamicFilters[filterType].forEach((filter: any) => {
            // Check if the current filter's slug matches any of the slugs in the list
            if (slugData.includes(filter.slug)) {
                foundIds.push(filter._id);
            }
        });
    }
    return foundIds;
}

export function convertValuesOfAttributes(
    attributes: any,
    dataToConvert: string[]
) {
    // Convert keys with attribute values
    const convertedData: any = {};
    for (const key in dataToConvert) {
        if (Object.hasOwnProperty.call(dataToConvert, key)) {
            const attribute = attributes[key];
            convertedData[attribute] = dataToConvert[key];
        }
    }
    return convertedData;
}

// export function convertDataToFormatOfFilters(slugsToFind: any, dynamicFilters: any) {
//   const attributes = [];

//   for (const [key, slugs] of Object.entries(slugsToFind)) {
//     slugs.forEach((slug: any) => {
//       dynamicFilters[key].forEach((item: any) => {
//         if (item.slug === slug) {
//           attributes.push({
//             attribute: key,
//             selectedValues: [item._id],
//           });
//         }
//       });
//     });
//   }
// }
